.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
input[type='date'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control,
input[type='time'].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.form-control:focus::-ms-value {
  color: #6e707e;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #858796;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -ms-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-border-radius: 0.35rem;
  -moz-border-radius: 0.35rem;
  -ms-border-radius: 0.35rem;
  -o-border-radius: 0.35rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6e707e;
}
.form-control:focus {
  color: #6e707e;
  background-color: #fff;
  border-color: #bac8f3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #858796;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #858796;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #858796;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #858796;
  opacity: 1;
}
.form-control::placeholder {
  color: #858796;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #eaecf4;
  opacity: 1;
}
.phone-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 57px;
  background-color: #f5f5f5;
}
.phone-select input {
  width: 50%;
  max-height: 100%;
  padding-top: 0;
  min-height: 100%;
  border-radius: 0;
  padding-bottom: 0;
}
.phone-select .PhoneInputCountry {
  width: 50%;
  min-height: 100%;
  display: flex;
  border: none !important;
  background-color: #f5f5f5;
}
.phone-select .PhoneInputCountry select {
  max-width: 70%;
  min-height: 100%;
  text-overflow: ellipsis;
  border: none !important;
}
.phone-select div:first-of-type {
  min-width: 30px;
  height: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 3px;
  border: none !important;
}
.phone-select div:first-of-type img {
  width: 100%;
  height: 100%;
}
.inputGroup {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;
  border: 1px solid #5562eb;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
}
.inputGroup label {
  padding: 12px 30px;
  width: 100%;
  display: block;
  text-align: left;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
  -webkit-transition: color 200ms ease-in;
  -moz-transition: color 200ms ease-in;
  -ms-transition: color 200ms ease-in;
  -o-transition: color 200ms ease-in;
}
.inputGroup .labelBefore {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: '';
  background-color: #5562eb;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  -moz-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  -ms-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  -o-transform: translate(-50%, -50%) scale3d(1, 1, 1);
}

.inputGroup input:checked ~ label {
  color: #fff;
}
.inputGroup input:checked ~ label .labelBefore {
  transform: translate(-50%, -50%) scale3d(76, 76, 1);
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale3d(100, 100, 1);
  -moz-transform: translate(-50%, -50%) scale3d(76, 76, 1);
  -ms-transform: translate(-50%, -50%) scale3d(76, 76, 1);
  -o-transform: translate(-50%, -50%) scale3d(76, 76, 1);
}

.inputGroup:hover label {
  color: #fff;
}
.inputGroup:hover label .labelBefore {
  transform: translate(-50%, -50%) scale3d(100, 100, 1);
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale3d(100, 100, 1);
  -moz-transform: translate(-50%, -50%) scale3d(100, 100, 1);
  -ms-transform: translate(-50%, -50%) scale3d(100, 100, 1);
  -o-transform: translate(-50%, -50%) scale3d(100, 100, 1);
}

.inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.inputGroupContainer {
  padding: 0 16px;
  max-width: 900px;
  margin: 0px auto;
}

.recent__courses__details_sidebar {
  max-height: 50vh;
  overflow-y: scroll;
}

.recent__courses__details_sidebar::-webkit-scrollbar {
  width: 3px;
  background-color: #ddd;
}

.recent__courses__details_sidebar::-webkit-scrollbar-thumb {
  background-color: #3f3a64;
}

.related__courses__carosel {
  overflow-x: scroll;
}

.related__courses__carosel::-webkit-scrollbar {
  height: 5px;
  background-color: #ddd;
}

.related__courses__carosel::-webkit-scrollbar-thumb {
  background-color: skyblue;
}

@media (max-width: 769px) {
  .home_page_course_card {
    max-width: unset !important;
    width: 100%;
  }
}

.header__uhe__log {
  overflow: hidden;
  mix-blend-mode: multiply;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  transition: 0.5s;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.header__uhe__log:hover {
  transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
}

.privacy-policy-wrapper ul {
  list-style-type: decimal;
}
.privacy-policy-wrapper h1,
.privacy-policy-wrapper h2,
.privacy-policy-wrapper h3 {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}
.privacy-policy-wrapper h1 {
  font-weight: 900;
  margin: 1em 0;
  font-size: 1.5em;
}
.privacy-policy-wrapper h2 {
  font-weight: 700;
  margin: 1em 0;
  font-size: 1.2em;
}
.privacy-policy-wrapper h3 {
  font-weight: 500;
  margin: 1em 0;
  font-size: 1em;
}

.shop__item__button {
  border: 1px solid #000;

  max-width: 50%;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.shop__item__button:hover {
  border: 1px solid #ddd;
  background-color: #000;
  color: #fff;
}

.in__cart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #3f3a64;
  color: #fff;
  border: 1px solid;
  border-color: #3f3a64;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: not-allowed;
  font-size: large;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: fit-content;
}
@media only screen and (max-width: 767px) {
  .in__cart {
    width: 80%;
  }
}
.shop__wishlist__icon:hover svg {
  fill: #ff0000;
}

.shop__wishlist__icon__loader {
  background-color: #fff;
}
.dont__show__arrows::-webkit-outer-spin-button,
.dont__show__arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.dont__show__arrows {
  -moz-appearance: textfield;
}
.wishlist__delete {
  font-size: 2em;
  cursor: pointer;
  transition: 0.3s;
}
.wishlist__delete:hover {
  color: red;
}

.shop__item__details__container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.shop__item__details__container .img_container img {
  object-fit: cover;
  width: 100%;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
}
.shop__item__details__container .info {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.shop__item__details__container .info .big_text {
  margin-bottom: 0.5rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
}
.shop__item__details__container .info .small_text {
  color: #374151;
}

.shop__item__details__container .buttons_container {
  display: flex;
  padding-top: 1.5rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.match_test_paper_container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1280px) {
  .match_test_paper_container {
    width: 70%;
  }
}
@media (min-width: 768px) {
  .shop__item__details__container {
    flex-direction: row;
  }
  .shop__item__details__container .img_container {
    width: 33.333333%;
  }
  .shop__item__details__container .info {
    padding-left: 5rem;
    width: 66.666667%;
  }
  .match_test_paper_container {
    width: 80%;
  }
}
.fxt__test__name {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.fxt__test__question {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.no-hover:hover {
  color: unset;
}

.display__all__answers__container .border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}
.display__all__answers__container .border-b-2:not(:last-child) {
  border-bottom-width: 2px;
}
.display__all__answers__container .pb-4 {
  padding-bottom: 1rem /* 16px */;
}
.display__all__answers__container .mb-4 {
  margin-bottom: 1rem /* 16px */;
}
.display__all__answers__container .border-dashed {
  border-style: dashed;
}
.display__all__answers__container .font-medium {
  font-weight: 500;
}
.display__all__answers__container .rounded-lg {
  border-radius: 0.5rem /* 8px */;
}
.display__all__answers__container .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.display__all__answers__container .bg-\[\#5562eb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(85 98 235 / var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .display__all__answers__container .sm\:max-w-\[75vw\] {
    max-width: 75vw;
    min-width: 75vw;
  }
}
@media (min-width: 768px) {
  .display__all__answers__container .md\:max-w-\[50vw\] {
    max-width: 50vw;
    min-width: 50vw;
  }
}

.password-container {
  position: relative;
}
.toggle-password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
}

.section-separator {
  position: relative;
}
.section-separator::before {
  content: '';
  position: absolute;
  right: 0;
  width: 100%;
  height: 30px;
  z-index: 1;
  background-image: linear-gradient(135deg, #20ad96 5%, transparent 5%),
    linear-gradient(225deg, #20ad96 30%, transparent 30%);
  background-size: 30px 30px;
}
.wave-separator {
  position: relative;
  width: 100%;
  height: 100px;
  background-color: #20ad955d;
  overflow: hidden;
}

.wave-separator:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: #fff;
  border-radius: 100% 100% 0 0;
  transform-origin: bottom center;
  transform: skewY(-7deg);
}

.wave-separator:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #20ad95c0;
  border-radius: 100% 100% 0 0;
  transform-origin: bottom center;
  transform: skewY(-12deg);
}

.styled-separator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

.line {
  flex: 1;
  height: 2px;
  background-color: #d3d3d3;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #20ad96;
  margin: 0 15px;
}

.shop-card-content:last-child {
  padding-bottom: 15px !important;
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f3a64;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
